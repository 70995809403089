<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="my-8">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>Session</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="refresh">
                                <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <template v-if="user">
                                <p class="text-overline mb-0">Authenticated</p>
                                <p class="mb-0 pb-0">
                                    <span>{{ user.name }}</span>
                                </p>
                                <p class="mb-0 pb-0">
                                    <span>{{ user.email }}</span>
                                </p>
                            </template>
                            <template v-if="!user">
                                <p class="text-overline mb-0">Not Authenticated</p>
                            </template>
                            <p class="mb-0 pb-0 mt-8">
                                <v-btn :style="primaryButtonStyle" @click="deleteSession">Delete session</v-btn>
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

/*
NOTE: unlike profile/Session.vue which is focused on the user's authenticated session,
this top-level Session.vue is public and allows users to easily clear their session
(because it can be hard to find in browser settings), and is also useful for developers
to clear various things while testing.
*/

export default {
    data: () => ({
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            brandprofile: (state) => state.brandprofile,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),
    },
    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.display_name;
                setTimeout(() => { this.$nextTick(() => this.$refs.displayNameInput.focus()); }, 1);
            }
        },
    },
    methods: {
        init() {
            console.log('profile.vue: init');
        },
        async refresh() {
            try {
                await this.$store.dispatch('refresh');
            } catch (err) {
                console.error('refresh failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to refresh session' });
            }
        },
        async deleteSession() {
            try {
                await this.$client.site(this.brandprofile).authn.deleteSession();
                this.$bus.$emit('snackbar', { type: 'success', headline: 'Deleted session' });
                await this.$store.dispatch('refresh');
            } catch (err) {
                console.error('deleteSession failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete session' });
            }
        },
    },
    mounted() {
        // when user is looking at their session, we de-select whatever account may have been selected because now they're in between accounts
        this.$store.commit('account', null);
        this.$nav.set('account_id', null);
        this.init();
    },
};
</script>
